<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane :key="1" tab="未领取">
      <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane :key="2" tab="已领取">
      <table-page :config="configRejected" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { registerChannel, registerPlatform } from '@/utils/public';

export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: 1,
      config: {
        checkbox: true,
        dataUrl: `/clientUser/admin/getClientUserList?pickUp=0`,
        scroll: { x: 2000 },
        filter: {
          controls: [
            {
              key: 'time',
              label: '注册时间',
              type: 'dateRange'
            },
            {
              key: 'nicknameOrUid',
              label: '昵称/UID',
              type: 'text'
            },
            {
              key: 'registerChannel',
              label: '注册渠道',
              type: 'select',
              config: {
                options: registerChannel
              }
            },
            {
              key: 'registrationPlatform',
              label: '注册平台',
              type: 'select',
              config: {
                options: registerPlatform
              }
            },
          ]
        },
        columns: [
          {
            key: 'time',
            title: '注册时间',
            width: '150px',
            type: 'datetime'
          },
          {
            key: 'registerChannel',
            title: '注册渠道',
            width: '150px',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if(val.registerChannel == '推广链接' && val.attributionName) {
                  return `${val.registerChannel}-${val.attributionName}`
                } else {
                  return val.registerChannel
                }
              }
            }
          },
          {
            key: 'registrationPlatform',
            title: '注册平台',
            width: '150px',
          },
          {
            key: 'uid',
            title: 'UID',
            width: '150px',
          },
          {
            key: 'nickname',
            title: '昵称',
            width: '150px',
          },
          {
            key: 'phoneNumber',
            title: '电话',
            width: '150px',
          },
          {
            key: 'wechat',
            title: '微信',
            width: '150px',
          },
          {
            key: 'degree',
            title: '最高学历',
            width: '150px',
          },
          {
            key: 'graduationTime',
            title: '毕业时间',
            width: '150px',
          },
          {
            key: 'gpa',
            title: 'GPA',
            width: '150px',
          },
          {
            key: 'langGrades',
            title: '语言成绩',
            width: '150px',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if(val.langGrades) {
                  return `${val.languageGradeType ? val.languageGradeType : ''} ${val.langGrades}`
                }
              }
            }
          },
          {
            key: 'intentionCountry',
            title: '目标国家/地区',
            width: '100px',
          },
          {
            key: 'applyProfessionalDesc',
            title: '目标方向',
            width: '100px',
          },
          {
            key: 'applyDegree',
            title: '申请阶段',
            width: '100px',
          },
          {
            key: 'applyYearBudget',
            title: '留学年预算',
            width: '100px',
          },
          {
            key: 'intentionService',
            title: '办理业务',
            width: '100px',
          },
          {
            key: 'budget',
            title: '申请预算',
            width: '100px',
          },
          {
            key: 'city',
            title: '城市',
            width: '100px',
          },
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'edit',
            text: '领取客户',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/myStudyAbroadIntention/pickUpCustomers',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length}位用户，是否领取？`
            },
            config: {
              title: '领取客户',
              submitUrl: '/clientUser/myStudyAbroadIntention/pickUpCustomers',
              submitText: '确认',
              isRepeat: true,
              repeatUrl: '/clientUser/myStudyAbroadIntention/mergeCustomer',
              controls: []
            }
          },
          {
            key: 'edit',
            text: '分配客户',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/myStudyAbroadIntention/allocateCustomers',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 位用户，请选择归属人 `
            },
            config: {
              title: '分配客户',
              initUrl: '/retention/retentionClients/assignCustomers/get',
              submitUrl: '/clientUser/myStudyAbroadIntention/allocateCustomers',
              submitText: '确认',
              isRepeat: true,
              repeatUrl: '/clientUser/myStudyAbroadIntention/mergeCustomer',
              controls: [
                {
                  key: 'attributionId',
                  label: '归属人',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: "employeesList"
                  },
                },
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
      },
      configRejected: {
        dataUrl: `/clientUser/admin/getClientUserList?pickUp=1`,
        scroll: { x: 2000 },
        filter: {
          controls: [
            {
              key: 'time',
              label: '注册时间',
              type: 'dateRange'
            },
            {
              key: 'nicknameOrUid',
              label: '昵称/UID',
              type: 'text'
            },
            {
              key: 'registerChannel',
              label: '注册渠道',
              type: 'select',
              config: {
                options: registerChannel
              }
            },
            {
              key: 'registrationPlatform',
              label: '注册平台',
              type: 'select',
              config: {
                options: registerPlatform
              }
            },
          ]
        },
        columns: [
          {
            key: 'time',
            title: '注册时间',
            width: '150px',
            type: 'datetime'
          },
          {
            key: 'registerChannel',
            title: '注册渠道',
            width: '150px',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if(val.registerChannel == '推广链接' && val.attributionName) {
                  return `${val.registerChannel}-${val.attributionName}`
                } else {
                  return val.registerChannel
                }
              }
            }
          },
          {
            key: 'registrationPlatform',
            title: '注册平台',
            width: '150px',
          },
          {
            key: 'uid',
            title: 'UID',
            width: '150px',
          },
          {
            key: 'nickname',
            title: '昵称',
            width: '180px',
          },
          {
            key: 'phoneNumber',
            title: '电话',
            width: '150px',
          },
          {
            key: 'wechat',
            title: '微信',
            width: '150px',
          },
          {
            key: 'degree',
            title: '最高学历',
            width: '150px',
          },
          {
            key: 'graduationTime',
            title: '毕业时间',
            width: '100px',
          },
          {
            key: 'gpa',
            title: 'GPA',
            width: '100px',
          },
          {
            key: 'langGrades',
            title: '语言成绩',
            width: '150px',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if(val.langGrades) {
                  return `${val.languageGradeType ? val.languageGradeType : ''} ${val.langGrades}`
                }
              }
            }
          },
          {
            key: 'intentionCountry',
            title: '目标国家/地区',
            width: '100px',
          },
          {
            key: 'applyProfessionalDesc',
            title: '目标方向',
            width: '100px',
          },
          {
            key: 'applyDegree',
            title: '申请阶段',
            width: '100px',
          },
          {
            key: 'applyYearBudget',
            title: '留学年预算',
            width: '100px',
          },
          {
            key: 'intentionService',
            title: '办理业务',
            width: '100px',
          },
          {
            key: 'budget',
            title: '申请预算',
            width: '100px',
          },
          {
            key: 'city',
            title: '城市',
            width: '100px',
          },
        ],
        /* 表格操作 */
        actions: [],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
      },

    };
  }

};
</script>

<style lang="less" scoped></style>